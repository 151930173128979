(function($){

  var app;
  app = {
    init: function() {
      this._tabsMenu();
      this._fixedHeader();
      this._validationJS();
      this._showBlock();
      this._slider();
      this._fixedContact();
      this._range();
      this._colorBox();
      this._select2();
      this._popup();
      this._clickByHash();
      // this._cookieBar();
    },
     _clickByHash: function() {
      $('.b-produit').on('click', 'a[href^="#"]', function (event) {
        console.log(this);
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - $('header').outerHeight(true)
        }, 2000);
      });
    },
    _cookieBar: function() {
      $.cookieBar({
        forceShow: true,
        message: 'En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour optimiser les performances de ce site et vous proposer des services et offres adaptés à vos préférences et vos centres d’intérêts. <a href="/mentions-legales">En savoir plus</a>',
        acceptButton: true,
        acceptText: 'J’accepte',
        autoEnable: true,
        effect: 'slide',
        fixed: true,
        bottom: true
      });
    },
    _popup: function() {
      $('.btn-show-popup').on('click', function(e) {
        e.preventDefault();
        $(this).next('.b-popup').fadeIn();
      });
      $('.btn-popup').on('click', function() {
        $(this).parent('.b-popup').fadeOut();
      });
    },
    _select2: function() {
      if ($(".chosen-select").length) {
        $(".chosen-select").select2({
          minimumResultsForSearch: Infinity,
          width: '100%'
        });
      }
    },
    _colorBox: function() {
      if ($('.image').length) {
        $('.image').colorbox({
          fixed:true,
          maxWidth: '90%',
          maxHeight: '90%'
        });
      }
      if ($('.video').length) {
        $('.video').colorbox({
          iframe: true,
          fixed:true,
          innerWidth:640,
          innerHeight:390,
          maxWidth: '90%',
          maxHeight: '90%'
        });
      }
      if ($('.cboxElement').length) {
        $(window).on("resize", function() {
          if ($(window).width() < 767) {
            $.fn.colorbox.resize({
              width: "90%",
              height: "56.25%"
            });
          } else {
            $.fn.colorbox.resize({
              innerWidth:640,
              innerHeight:390
            });
          }
          
        });
      }
      
      
    },
    _range: function() {
      if ($("#input_ranger").length) {
        $("#input_ranger").ionRangeSlider({
          "min": 38,                // MIN value
          "max": 82,              // MAX values
          "from_min" : 40,
          "from_max": 80,
          "postfix": " HL",
          "from" : 50,
          onStart: function() {
            $('.irs-min').text(this.from_min + " HL");
            $('.irs-max').text(this.from_max + " HL");
          },
          onChange: function(data) {
            $('.irs-min').text(this.from_min + " HL");
            $('.irs-max').text(this.from_max + " HL");
          },
        });

        var rangeOpions = $("#input_ranger").data().ionRangeSlider.options;
        function getParamsRange() {
          $('.irs-min').text(rangeOpions.from_min + " HL");
          $('.irs-max').text(rangeOpions.from_max + " HL");
        }
        getParamsRange();
        
        $(window).on('resize', function() {
          setTimeout(function() {
            getParamsRange();
          }, 200);
          
        });
      }
    },
    _fixedContact: function() {
      $('.btn-contact-fixed').on('click', function(e) {
        e.preventDefault();
        $(this).parent('.fixed-contact').toggleClass('is-show');
      });

      $('.b-contact').on('click', '.btn-contact-fixed', function (event) {
        event.preventDefault();
        $($.attr(this, 'href')).fadeIn();
        
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 70
        }, 1000);
      });

      
    },
    _slider: function() {
      if ($('.slider__home').length && $('.slider__home .bandeau').length > 1) {
        $('.slider__home').slick({
          dots: false,
          arrows: false,
          infinite: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          adaptiveHeight: true
        });
      }
      
      
    },
    _showBlock: function() {
      $('.show-block').on('click', function(e) {
        e.preventDefault();
        $(this).parent('.block-sm').toggleClass('is-show');
        if ($(this).parent('.block-sm').hasClass('is-show')) {
          $(this).text('Lire moins');
        } else {
          $(this).text('Lire plus');
        }
      });

      // console.log($('.block-sm__body .p').css('margin-bottom'));

      // $('.block-sm__body .p').height((parseFloat(($('.block-sm__body .p').css('line-height')).replace('px', ''))*4));

      

    },
    _fixedHeader: function() {
      $('header').on('show.bs.collapse', function () {
        $(this).addClass('is-active');
        $('body').addClass('is-clipped');
      });
      $('header').on('hide.bs.collapse', function () {
        $(this).removeClass('is-active');
        $('body').removeClass('is-clipped');
      });
      $('#navbarPrincipalMenu').on('click', '.nav-link', function() {
        $('#navbarPrincipalMenu').collapse('hide');
      });
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('header.header-home').addClass('is-sticky');
         }
         else {
          $('header.header-home').removeClass('is-sticky');
         }
      });
    },
    _tabs: function() {
      $('.tabs .tab-links a').on('click', function(e)  {
        var currentAttrValue = $(this).attr('href');
 
        // Show/Hide Tabs
        $('.tabs ' + currentAttrValue).show().siblings().hide();
 
        // Change/remove current tab to active
        $(this).parent('li').addClass('active').siblings().removeClass('active');
 
        e.preventDefault();
      });
    },
    _tabsMenu: function() {
      $('.tabs__menu').on('click', '.tab-panel > li', function(e) {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
          $(this).children('ul').slideUp();
        } else {
          $(this).parents('.tabs__menu').find('li.active').removeClass('active').children('ul').slideUp();
          $(this).addClass('active');
          $(this).children('ul').slideDown();
        }
      });

    },
    _validationJS: function() {
      //Redefinition des messages de validation
      $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        remote: "Veuillez corriger ce champ.",
        email: "Veuillez fournir une adresse électronique valide.",
        url: "Veuillez fournir une adresse URL valide.",
        date: "Veuillez fournir une date valide.",
        dateISO: "Veuillez fournir une date valide (ISO).",
        number: "Veuillez fournir un numéro valide.",
        digits: "Veuillez fournir seulement des chiffres.",
        creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
        equalTo: "Veuillez fournir encore la même valeur.",
        extension: "Veuillez f ournir une valeur avec une extension valide.",
        maxlength: $.validator.format("Veuillez fournir au plus {0} caractères."),
        minlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
        rangelength: $.validator.format("Veuillez fournir une valeur qui contient entre {0} et {1} caractères."),
        range: $.validator.format("Veuillez fournir une valeur entre {0} et {1}."),
        max: $.validator.format("Veuillez fournir une valeur inférieure ou égale à {0}."),
        min: $.validator.format("Veuillez fournir une valeur supérieure ou égale à {0}."),
        step: $.validator.format("Veuillez fournir une valeur multiple de {0}."),
        maxWords: $.validator.format("Veuillez fournir au plus {0} mots."),
        minWords: $.validator.format("Veuillez fournir au moins {0} mots."),
        rangeWords: $.validator.format("Veuillez fournir entre {0} et {1} mots."),
        letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
        alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
        lettersonly: "Veuillez fournir seulement des lettres.",
        nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
        ziprange: "Veuillez fournir un code postal entre 902xx-xxxx et 905-xx-xxxx.",
        integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
        vinUS: "Veuillez fournir un numéro d'identification du véhicule (VIN).",
        dateITA: "Veuillez fournir une date valide.",
        time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
        phoneUS: "Veuillez fournir un numéro de téléphone valide.",
        phoneUK: "Veuillez fournir un numéro de téléphone valide.",
        mobileUK: "Veuillez fournir un numéro de téléphone mobile valide.",
        strippedminlength: $.validator.format("Veuillez fournir au moins {0} caractères."),
        email2: "Veuillez fournir une adresse électronique valide.",
        url2: "Veuillez fournir une adresse URL valide.",
        creditcardtypes: "Veuillez fournir un numéro de carte de crédit valide.",
        ipv4: "Veuillez fournir une adresse IP v4 valide.",
        ipv6: "Veuillez fournir une adresse IP v6 valide.",
        require_from_group: "Veuillez fournir au moins {0} de ces champs.",
        nifES: "Veuillez fournir un numéro NIF valide.",
        nieES: "Veuillez fournir un numéro NIE valide.",
        cifES: "Veuillez fournir un numéro CIF valide.",
        postalCodeCA: "Veuillez fournir un code postal valide.",
        regex: "Adresse email invalide"
      });

      $.validator.setDefaults({ ignore: ":hidden:not(.chosen-select)" });

      if ($('#form-search-footer2').length) {
        $('#form-search-footer2').validate({
          rules: {
            "search": "required"
          }
        });
      }

      if ($("#contact-form").length) {
        $("#contact-form").validate({
          rules: {
            "nom": "required",
            "prenom": "required",
            "email": "required",
            "tel": {
              required: true,
              email: true
            },
            "message": "required"
          }
        });
      }

      var optionsContactForm = {
          rules: {
            "objet": "required",
            "nomEntreprise": "required",
            "nom": "required",
            "prenom": "required",
            "email": {
              required: true,
              email: true
            },
            "tel": {
              required: true,
              number: true,  // <-- no such method called "matches"!
              minlength:10,
              maxlength:10
            },
            "message": "required"
          }
        }

      if ($("#contact-form-simonneau").length) {
        $("#contact-form-simonneau").validate(optionsContactForm);
      }
      if ($("#contact-form-maitre").length) {
        $("#contact-form-maitre").validate(optionsContactForm);
      }

      

      

      if ($('#form-postal').length) {
        $('#form-postal').validate({
          rules: {
            "zipCode": "required"
          }
        });
      }

      

      if ($("#contact-fixed-form").length) {
        $("#contact-fixed-form").validate({
          rules: {
            "object": "required",
            "nom": "required",
            "prenom": "required",
            "email": "required",
            "tel": {
              required: true,
              email: true
            },
            "message": "required"
          }
        });
      }
    }
  };

  app.init();


}(jQuery));